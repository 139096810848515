import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserI} from '../interfaces/user.interface';
import {AuthDataI, AuthDataTokenI} from '../interfaces/auth.interface';

interface UserStoreI {
  user: UserI;
  token: AuthDataTokenI | undefined;
  inited: boolean;
  loading: boolean;
}

@Injectable({providedIn: 'root'})
export class UserStore {
  private userStore = new BehaviorSubject<UserStoreI>({
    user: null as any,
    token: null as any,
    inited: false,
    loading: false
  })

  isLoading$ = this.userStore.asObservable().pipe(
    map(data => data.loading)
  );

  user$ = this.userStore.asObservable().pipe(
    map(data => data.user)
  )

  token$ = this.userStore.asObservable().pipe(
    map(data => data.token)
  );

  setLoading(loading: boolean) {
    this.userStore.next({
      ...this.userStore.getValue(),
      loading
    })
  }

  setToken(token: AuthDataTokenI | undefined) {
    this.userStore.next({
      ...this.userStore.getValue(),
      token: token
    })
  }

  setUser(user: any) {
    this.userStore.next({
      ...this.userStore.getValue(),
      user
    })
  }

  get currentUser() {
    return this.userStore.getValue().user;
  }

  get token() {
    return this.userStore.getValue().token;
  }
}
