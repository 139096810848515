import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import {map, catchError, switchMap, finalize, tap, filter} from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {UserStore} from '../../../common/custom-stores/user.store';
import {AuthDataTokenI} from '../../../common/interfaces/auth.interface';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private userStore: UserStore
  ) {
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string) {
    this.userStore.setLoading(true);
    return this.authHttpService.login(email, password).pipe(
      filter((user) => !!user),
      tap((user) => {
        console.log('u', user)
        this.setAuthFromLocalStorage(user);
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.userStore.setLoading(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    console.log('auth', auth);
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    this.userStore.setToken(auth);
    this.userStore.setLoading(true);

    return this.authHttpService.getCurrentUser().pipe(
      map((user: UserType) => {
        if (user) {
          this.userStore.setUser(user);
        } else {
          this.logout();
        }
        return user;
      }),
      catchError(err => {
        return this.authHttpService.refreshUser().pipe(
          tap((user) => {
            this.setAuthFromLocalStorage(user);
          }),
          switchMap(() => this.getUserByToken()),
          catchError((e) => {
            this.logout();
            throw err;
          })
        )
      }),
      finalize(() => this.userStore.setLoading(false))
    );
  }


  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    console.log('auth2', auth);
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage():  AuthDataTokenI | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
