import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { AuthModel } from '../../models/auth.model';
import {Api2Service} from '../../../../common/services/api2.service';


@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(
    private http: HttpClient,
    private api2Service: Api2Service
  ) {}

  // public methods
  login(email: string, password: string): Observable<AuthModel> {
    return this.api2Service.post<AuthModel>(`admin/auth/login`, {
      email,
      password,
    });
  }

  getCurrentUser(): Observable<UserModel> {
    return this.api2Service.get<UserModel>(`admin/auth/me`);
  }

  refreshUser(): Observable<AuthModel> {
    return this.api2Service.get<AuthModel>(`admin/auth/refresh`);
  }



}
